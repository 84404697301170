<template>
  <tab-container>
    <div slot="operation">
      <a-input
        v-model="keyword"
        style="width: 180px; margin-right: 18px;"
        @input.stop="() => handleSearch()"
        placeholder="搜索姓名/手机号"
      />
      <a-range-picker
        style="width: 250px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
        @change="initData"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      :scroll="{ x: '100%', y: 'max-content' }"
      @change="handlePagination"
      :transformCellText="({ text }) => (text === -1 ? '-' : text || '-')"
    />
  </tab-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceTypeEnum } from '@/enum/device';
import RecordTable from '@/views/green-construction/components/mixins/record-table';
import { deviceRecord } from '@/services/device-manage/device-detail';
import { exchangeDirectionValue } from '@/views/green-construction/components/utils';
import OssImage from '@/views/screen/components/oss-image.vue';
import { Debounce } from 'lodash-decorators';

@Component({
  components: {
    TabContainer,
    OssImage,
  },
})
export default class TurnoverLog extends mixins(RecordTable) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('detail.name'),
        dataIndex: 'personName',
        ellipsis: true,
        width: 110,
      },
      {
        align: 'left',
        title: '进出场图片',
        dataIndex: 'photoUrl',
        customRender: text =>
          text ? <oss-image basePath="/oss/iot/oss" ossPath={text} /> : '',
        width: 120,
      },
      {
        align: 'left',
        title: '进出类型',
        dataIndex: 'direction',
        ellipsis: true,
        customRender: text => (text ? this.getDirection(text) : ''),
        width: 100,
      },
      {
        align: 'left',
        title: '进出场时间',
        dataIndex: 'time',
        ellipsis: true,
        width: 180,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: '身份证号',
        dataIndex: 'idCard',
        ellipsis: true,
        width: 180,
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.phoneNumber'),
        dataIndex: 'phoneNum',
        ellipsis: true,
        width: 150,
      },
      {
        align: 'left',
        title: '工人类型',
        dataIndex: 'personRole',
        ellipsis: true,
        width: 200,
      },
      {
        align: 'left',
        title: '工人所属项目',
        dataIndex: 'projectName',
        ellipsis: true,
        width: 180,
      },
    ];
  }
  getDirection(t) {
    return exchangeDirectionValue(t);
  }
  keyword = '';
  getRecordList(queryParams, deviceGroup) {
    queryParams.keyword = this.keyword;
    return deviceRecord(queryParams, deviceGroup);
  }
  deviceGroup = deviceTypeEnum.FG.deviceGroup;
  @Debounce(500)
  handleSearch() {
    this.loadTableData();
  }
}
</script>

<style lang="less" module>
.tableImg {
  cursor: pointer;
  img {
    object-fit: contain;
    width: 30px;
  }
}
</style>
